<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <div id="container">
        <ion-item
          id="item"
          class="ion-margin-top margen9pxLados itemColor"
          size="auto"
        >
          <ion-row width="200px">
            <ion-col>
              <ion-text color="dark">
                Lote
              </ion-text>

              <br />
              <ion-text
                class="ion-text-capitalize negrita"
                style="white-space: normal"
                color="dark"
              >
                {{ nombreLoteCiudad }}
              </ion-text>
              <br />
              <ion-text color="secondary">
                <i>{{ "Sup. " + hectareasLote + " Ha" }}</i>
              </ion-text>
              <br />
              <ion-text color="dark">
                Establecimiento:
              </ion-text>
              <ion-text
                class="ion-text-capitalize"
                style="white-space: normal"
                color="dark"
              >
                <b>
                  {{ nombreEstablecimientoLote }}
                </b>
              </ion-text>
            </ion-col>
          </ion-row>
        </ion-item>

        <ion-card color="light">
          <ion-card-header style="padding-bottom: 4px">
            <ion-row>
              <ion-text
                class="negrita2 ion-align-self-center ion-text-capitalize"
              >
                Cultivos
              </ion-text>
              <ion-fab-button
                @click="modalCampaniaCreate"
                size="small"
                class="ion-margin-left"
              >
                <ion-icon
                  color="light"
                  :md="icons.add"
                  :ios="icons.add"
                ></ion-icon>
              </ion-fab-button>
            </ion-row>
          </ion-card-header>
          <ion-card-content>
            <ion-list
              v-for="cc in campaniasCultivos"
              :value="cc"
              :key="cc._id"
              style="padding-top: 0px; padding-bottom: 0px;"
            >
              <ion-grid fixed>
                <ion-row
                  class="ion-align-items-center ion-justify-content-start"
                >
                  <ion-col size="auto" style="padding-right: 0px;">
                    <ion-button
                      size="small"
                      fill="clear"
                      style="margin-left: 0px; margin-right: 0px;"
                      @click="setFavorito(cc._id)"
                    >
                      <ion-icon
                        v-if="!cc.favorito"
                        color="dark"
                        :md="icons.noFav"
                        :ios="icons.noFav"
                      ></ion-icon>
                      <ion-icon
                        v-if="cc.favorito"
                        color="dark"
                        :md="icons.fav"
                        :ios="icons.fav"
                      ></ion-icon>
                    </ion-button>
                  </ion-col>
                  <ion-col
                    size="auto"
                    style="padding-left: 0px; padding-right: 0px;"
                  >
                    <ion-button
                      size="small"
                      fill="clear"
                      style="margin-left: 0px; margin-right: 0px;"
                      :disabled="true"
                      class="disabledButtonOpacity"
                    >
                      <ion-icon
                        v-if="!cc.abierto"
                        color="dark"
                        :md="icons.closed"
                        :ios="icons.closed"
                      ></ion-icon>
                      <ion-icon
                        v-if="cc.abierto"
                        color="primary"
                        :md="icons.open"
                        :ios="icons.open"
                      ></ion-icon>
                    </ion-button>
                  </ion-col>
                  <ion-col>
                    <ion-item
                      button="true"
                      type="button"
                      @click="goToCampaniaCultivoInLote(cc._id)"
                    >
                      <ion-text style="white-space: normal">
                        {{
                          cc.campania.nombre +
                            " - " +
                            (cc.cultivo && cc.cultivo.nombre
                              ? cc.cultivo.nombre
                              : "Otro") +
                            " (" +
                            (cc.variedadCultivo && cc.variedadCultivo.variedad
                              ? cc.variedadCultivo.variedad
                              : "Otra") +
                            ")"
                        }}
                      </ion-text>
                    </ion-item>
                  </ion-col>
                  <ion-col
                    size="auto"
                    style="padding-left: 0px; padding-right: 0px;"
                  >
                    <ion-button
                      size="small"
                      fill="clear"
                      style="margin-left: 0px; margin-right: 0px;"
                      @click="modalCampaniaEdit(cc)"
                    >
                      <ion-icon
                        color="dark"
                        :md="icons.edit"
                        :ios="icons.edit"
                      ></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-list>
          </ion-card-content>
        </ion-card>

        <ion-button
          @click="volver"
          class="ion-margin-top ion-margin-bottom ion-float-end margen9pxLados"
          color="primary"
        >
          Volver
        </ion-button>

        <br />
        <br />
        <br />
        <br />

        <ion-modal
          id="modalCampania"
          :is-open="modalEditCampania"
          @didDismiss="modalEditCampania = false"
          :backdropDismiss="false"
        >
          <CampaniaForm
            :lote="lote"
            :error="error"
            :persona="persona"
            :campaniaCultivo="campaniaCultivo"
            @cancel="modalEditCampania = false"
            @submit="submitCampaniaCultivo"
            class="modal-wrapper"
          />
        </ion-modal>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  IonButton,
  IonContent,
  IonPage,
  IonCol,
  //IonImg,
  IonModal,
  IonList,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonItem,
  IonFabButton,
  IonGrid,
  // IonThumbnail,
  IonText,
  IonIcon,
  alertController
} from "@ionic/vue";
import CampaniaForm from "../../components/CampaniaForm.vue";
import http from "@/api/http";
import { CampaniaCultivos, Lotes, Persona, Establecimientos } from "@/api";
import {
  addOutline,
  pencilOutline,
  lockClosed,
  lockOpen,
  starOutline,
  star
} from "ionicons/icons";

export default {
  components: {
    IonButton,
    IonContent,
    IonIcon,
    IonList,
    IonGrid,
    IonModal,
    /*
    IonImg,
    IonThumbnail, */
    IonItem,
    IonCardHeader,
    IonFabButton,
    IonRow,
    IonCol,
    IonText,
    IonCard,
    IonCardContent,
    IonPage,
    CampaniaForm
  },
  data: () => ({
    success: null,
    campaniaCultivo: {},
    campaniasCultivos: [],
    productor: {},
    error: "",
    establecimiento: {},
    lote: {},
    nombreLoteCiudad: "",
    hectareasLote: "",
    nombreEstablecimientoLote: "",
    modalEditCampania: false,
    icons: {
      add: addOutline,
      edit: pencilOutline,
      fav: star,
      noFav: starOutline,
      open: lockOpen,
      closed: lockClosed
    }
  }),

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"]),
    editing() {
      return !!this.$route.params.idActividad;
    },
    idProductor() {
      return this.$route.params.idProductor;
    },
    persona() {
      if (this.isAdmin && this.idProductor) {
        if (this.productor && this.productor._id) {
          return this.productor;
        }
        return {};
      }
      return this.user;
    }
  },

  watch: {
    async editing(val) {
      if (val) {
        await this.dataSync();
      }
    },

    async idProductor() {
      await this.dataSync();
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
  },

  methods: {
    ...mapActions(["getMe"]),
    volver() {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-productor-lote",
          params: {
            idProductor: this.idProductor,
            tab: "Ac",
            idLote: this.lote._id
          }
        });
      } else {
        this.$router.push({
          name: "lote",
          params: {
            tab: "Ac",
            idLote: this.lote._id
          }
        });
      }
    },

    async setFavorito(id) {
      const { error } = await http.post("/api/save-favCamp", {
        campaniasCultivos: this.campaniasCultivos,
        campCultFavID: id
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error guardando Campaña Cultivo Favorita.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      await this.dataSync();
    },

    async dataSync() {
      if (this.idProductor && this.isAdmin) {
        this.productor = await this.getProductor(this.idProductor);
      }
      this.lote = await this.getLote();
      this.campaniasCultivos = await this.getCampaniasCultivos();
      this.campaniaCultivo = this.campaniasCultivos[0];

      this.nombreLoteCiudad =
        this.lote.nombre + " - " + this.lote.localidad || "";
      this.hectareasLote = this.lote.hectareas || "";

      if (this.lote.establecimiento) {
        this.establecimiento = await this.getEstablecimiento();
      } else {
        this.establecimiento = { id: "0", nombre: "-" };
      }
      this.nombreEstablecimientoLote = this.establecimiento.nombre;
      this.sortCampaniasCultivos();
    },

    sortCampaniasCultivos() {
      this.campaniasCultivos.sort((a, b) => {
        return a.campania.nombre
          .toLowerCase()
          .localeCompare(b.campania.nombre.toLowerCase());
      });
    },

    goToCampaniaCultivoInLote(id) {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-productor-lote",
          params: {
            idProductor: this.idProductor,
            tab: "Ac",
            idLote: this.lote._id
          },
          query: {
            campCult: id
          }
        });
      } else {
        this.$router.push({
          name: "lote",
          params: {
            tab: "Ac",
            idLote: this.lote._id
          },
          query: {
            campCult: id
          }
        });
      }
    },

    async getCampaniasCultivos() {
      const { data, error } = await CampaniaCultivos.get({
        query: { lote: this.lote._id },
        populate: "campania, cultivo, variedadCultivo",
        sort: { createdAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo campaña - cultivo",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getProductor(idProductor) {
      const { data, error } = await Persona.getBy_id(idProductor);
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Productor.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    modalCampaniaEdit(campCult) {
      this.campaniaCultivo = campCult;
      this.modalEditCampania = true;
    },

    modalCampaniaCreate() {
      this.campaniaCultivo = {};
      this.modalEditCampania = true;
    },

    modalCampaniaClose() {
      this.modalEditCampania = false;
      this.campaniaCultivo = {};
    },

    async getLote() {
      const { data, error } = await Lotes.getBy_id(this.$route.params.idLote);
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo lote nro" + this.$route.params.idLote,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getEstablecimiento() {
      const { data, error } = await Establecimientos.getBy_id(
        this.lote.establecimiento
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo establecimiento",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async submitCampaniaCultivo({ campaniaCultivo }) {
      this.error = "";
      const { /*data,*/ error } = await CampaniaCultivos.save(campaniaCultivo);
      if (error) {
        this.error = error;
      } else {
        this.success = true;
        const alert = await alertController.create({
          header: "Campaña - Cultivo",
          message: campaniaCultivo._id
            ? "Campaña - Cultivo Actualizada"
            : "Campaña - Cultivo Creada",
          buttons: [
            {
              text: "Aceptar",
              handler: () => {
                //this.$router.push({ name: "productor-lotes" });
                window.location.reload();
              }
            }
          ]
        });
        this.modalEditCampania = false;
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  background: transparent !important;
}

ion-modal {
  --ion-background-color: #ffffff85;
}

.negrita {
  font-size: 175%;
  margin-left: 0px;
  font-weight: bold;
}

.negrita2 {
  color: black;
  font-size: 125%;
  margin-left: 0px;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

.margen4px {
  margin-left: 4px;
}

.margen8px {
  margin-left: 8px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  float: right;
}

.width25 {
  width: 25%;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

.margen8pxArriba {
  margin-top: 8px;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.itemColor {
  --background: rgba(var(--ion-color-primary-rgb), 0.6);
  --border-color: rgba(var(--ion-color-primary-rgb), 0.6);
}

.disabledButtonOpacity[disabled] {
  opacity: 1 !important;
}

@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
}
</style>
